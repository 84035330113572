import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import PageHero from "./pagehero"
import Styles from "../styles/components/caseStudyLayout.module.scss"

export default function PageTemplate({ data: { caseStudiesJson } }) {
  return (
    <div>
      <PageHero
        heading={caseStudiesJson.frontmatter.companyName}
        description={caseStudiesJson.frontmatter.workTitle}
        imgSrc={`../../${caseStudiesJson.frontmatter.featureImage}`}
        pageTitle="Case Study"
      ></PageHero>
      <div className={`container-padding`}>
        <div>
          <div className={Styles["section-line--title"]}>
            <h5>Summary</h5>
          </div>
          <div className={Styles["summary-section"]}>
            <div className={Styles["summary-section-text"]}>
              <h5>CLIENT</h5>
              <p>{caseStudiesJson.frontmatter.companyName.toUpperCase()}</p>
              <h5>DELIVERABLES</h5>
              {caseStudiesJson.frontmatter.deliverables.map(deliverable => {
                return <p key={deliverable}>{deliverable}</p>
              })}
              <h5>CHALLENGE</h5>
              <p>{caseStudiesJson.challenge}</p>
              <h5>OUTCOME</h5>
              <p>{caseStudiesJson.outcome}</p>
            </div>
            <div className={Styles["summary-section-image"]}>
              <img
                src={`../../${caseStudiesJson.frontmatter.featureImage}`}
                alt="image for the case study"
              />
            </div>
          </div>
        </div>
      </div>
      <h2 className={`${Styles["coming-soon"]} container-padding`}>
        Detailed case study coming soon. <br></br> Click{" "}
        <a href={caseStudiesJson.frontmatter.liveUrl} target="_blank">
          here
        </a>{" "}
        to see the live project{" "}
      </h2>
      {/* <ScrollAnimation animateIn="fadeIn">
        <div className={`container-padding`}>
          <h1>Results by numbers</h1>
          <div className={`${Styles["case-study-result"]}`}>
            <div className={Styles["case-study-result-inner"]}>
              <h3>1785</h3>
              <p>Student Users</p>
            </div>
            <div className={Styles["case-study-result-inner"]}>
              <h3>10</h3>
              <p>Minutes - Time on Site</p>
            </div>
            <div className={Styles["case-study-result-inner"]}>
              <h3>5</h3>
              <p>Global Regions</p>
            </div>
            <div className={Styles["case-study-result-inner"]}>
              <h3>1.5</h3>
              <p>Percent Bounce Rate</p>
            </div>
            <div className={Styles["case-study-result-inner"]}>
              <h3>75</h3>
              <p>Percent Repeat Visitors</p>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <div>
        <Img
          alt="Phosmobile Ltd"
          filename={caseStudiesJson.frontmatter.featureImage}
        />
      </div>
      <ScrollAnimation animateIn="fadeIn">
        <div
          className={`${Styles["background"]} ${
            Styles["section-container"]
          } container-padding`}
        >
          <div className={Styles["section-line--content-container"]}>
            <div className={Styles["section-line--title"]}>
              <h5>The Story</h5>
            </div>
            <div className={Styles["section-divider"]}>
              <div className={Styles["section-divider--text"]}>
                <h1 className={Styles["section-title--big"]}>
                  Understanding the music community
                </h1>
                <ScrollAnimation animateIn="fadeIn">
                  <div className={Styles["section-text--divider"]}>
                    <p>
                      Fender’s appeal is broad. For retail employees to reach
                      both high-school band members and touring musicians alike,
                      appealing to a range of proficiencies in between, the
                      retail employees need to be educated in Fender’s
                      strengths. We had to consider sales associates without
                      direct ties to Fender too: increasing their sales
                      knowledge depended on our ability inspire through the use
                      the educational platform.
                      <br />
                      <br />
                      Creating a sense of community is essential in brand
                      building, and this is also true to digital experiences. We
                      provided users with more access to Fender, ensuring
                      inclusivity by catering for varying levels of expertise,
                      and inspiring every member with engaging media. Users
                      enjoy the useful digital utilities, such as reference
                      tools and product catalogues for the sales floor; they
                      find motivation in the discount and reward incentives;
                      they value the ‘Fender Certified’ achievement; both the
                      mobile and desktop experiences are pitch perfect.
                    </p>
                  </div>
                </ScrollAnimation>
                <hr className={Styles["hr"]}></hr>
                <h1 className={Styles["section-title--big"]}>
                  Empowering sales associates
                </h1>
                <ScrollAnimation animateIn="fadeIn">
                  <div className={Styles["section-text--divider"]}>
                    <p>
                      The fully-responsive and flexible framework of the website
                      empowers sales associates with the tools and knowledge to
                      feel at one with the Fender brand. Students using our
                      platform can, for example, begin their learning experience
                      on their laptop and continue later from the same stage on
                      their mobile, staying connected throughout the day.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <div className={Styles["img-wrappper"]}>
          <img alt="Phosmobile Ltd" src={img2} />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <div
          className={`${Styles["background"]} ${
            Styles["section-container"]
          } container-padding`}
        >
          <div className={Styles["section-line--content-container"]}>
            <div className={Styles["section-divider"]}>
              <div className={Styles["section-divider--text"]}>
                <h1 className={Styles["section-title--big"]}>
                  Reaching the people
                </h1>
                <ScrollAnimation animateIn="fadeIn">
                  <div className={Styles["section-text--divider"]}>
                    <p>
                      The educational platform needed to reach people. Our
                      experience encourages exploration, creativity, and
                      engagement. An inspiring brand such as Fender has certain
                      expectations; with rich media, we ensured the educational
                      experience suited its subject: music.
                      <br />
                      <br />
                      Users have the opportunity to creatively track, manage and
                      discover educational content. They test their knowledge,
                      earning Fender IQ points through chapter cards, course
                      objectives, and lesson-based curriculum.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <div className={Styles["img-wrappper"]}>
          <img alt="Phosmobile Ltd" src={img2} />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <div
          className={`${Styles["background"]} ${
            Styles["section-container"]
          } container-padding`}
        >
          <div className={Styles["section-line--content-container"]}>
            <div className={Styles["section-divider"]}>
              <div className={Styles["section-divider--text"]}>
                <h1 className={Styles["section-title--big"]}>
                  Rewriting the rules
                </h1>
                <ScrollAnimation animateIn="fadeIn">
                  <div className={Styles["section-text--divider"]}>
                    <p>
                      As always, solid research underpinned our making. By
                      spending time with several online schools, and educational
                      websites and platforms, we broke down the educational
                      process, extracting the most important, time-honored
                      methods, and we rebuilt the educational structure in its
                      most basic form.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <div
          className={`${Styles["background"]} ${
            Styles["section-container"]
          } container-padding`}
        >
          <div className={Styles["section-modular-wrapper"]}>
            <div className={Styles["section-modular"]}>
              <div className={Styles["section-modular-content"]}>
                <h1>Education</h1>
                <p>
                  By moving away from the more conventional methods of online
                  education, our experience educates users about the Fender
                  brand and its products in an engaging fashion that focuses on
                  discovery.
                </p>
              </div>
            </div>

            <div className={Styles["section-modular"]}>
              <div className={Styles["section-modular-img-wrapper"]}>
                <img alt="Phosmobile Ltd" src={img1} />
              </div>
              <div className={Styles["section-modular-content"]}>
                <h1>Modularity</h1>
                <p>
                  Our modular system empowers Fender’s technical team to update
                  content efficiently. It also provided the foundation for us to
                  build a system for content writers to publish exclusive
                  videos, audio, and supportive content.
                </p>
              </div>
            </div>
            <div className={Styles["section-modular"]}>
              <div className={Styles["section-modular-img-wrapper"]}>
                <img alt="Phosmobile Ltd" src={img2} />
              </div>
              <div className={Styles["section-modular-content"]}>
                <h1>Gamification</h1>
                <p>
                  Fender IQ is a feature that increases interest by rewarding
                  sales associates for playing the platform. This is an
                  important highlight in how we reinvented education; the entire
                  experience is enjoyable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="bounceIn">
        <AniLink
          cover
          to="/expertise/tech"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
          className={Styles["expertise-next-section-link"]}
        >
          <div className={Styles["expertise-next-section"]}>
            <p>Next Case Study</p>
            <h4>Technology Development Nimasa Agent</h4>
          </div>
        </AniLink>
      </ScrollAnimation> */}
      <Footer></Footer>
    </div>
  )
}

export const pageQuery = graphql`
  query CaseStudyQuery($id: String) {
    caseStudiesJson(id: { eq: $id }) {
      background
      id
      challenge
      outcome
      images
      x_factor
      strategy
      approach
      what_happened
      frontmatter {
        slug
        workTitle
        companyName
        workTitle
        workType
        featureImage
        deliverables
        liveUrl
      }
    }
  }
`
